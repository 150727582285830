import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch, InputLabel, Select, FormControl
} from '@mui/material';
import { ExpandMore, Edit, Delete, ContentCopy, Save } from '@mui/icons-material';
import axios from "axios";

const Sucursales = () => {
  const [nombre, setNombre] = useState('');
  const [organizaciones, setOrganizaciones] = useState([]);
  const [selectedOrganizacion, setSelectedOrganizacion] = useState('');
  const [estado, setEstado] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [telefono, setTelefono] = useState('');
  const [legal_Id, setlegal_Id] = useState('');
  const [sucursales, setSucursales] = useState([]);
  const [formularioAbierto, setFormularioAbierto] = useState(false);
  const [editandoId, setEditandoId] = useState(null);
  const [errorOrganizacion, setErrorOrganizacion] = useState('');

  const [metricas, setMetricas] = useState(false);
  const [miOrganizacion, setMiOrganizacion] = useState(false);
  const [regulaciones, setRegulaciones] = useState(false);
  const [checklist, setChecklist] = useState(false);
  const [documentos, setDocumentos] = useState(false);
  const [auditorias, setAuditorias] = useState(false);


  useEffect(() => { 
    const fetchOrganizaciones = async () => { 
    try { 
      const response = await axios.get('http://localhost:8080/api/organizacion/activas'); 
      setOrganizaciones(response.data.organizaciones); 
      } catch (error) { 
        console.error('Error obteniendo organizaciones', error); 
      } 
    }; 
    fetchOrganizaciones(); 
  },[]);

  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const nuevaSucursal = {
      id: editandoId !== null ? editandoId : sucursales.length + 1,
      nombre,
      organizacion: organizaciones.find((organizacion) => organizacion.nombre === selectedOrganizacion)?.id,
      estado,
      ciudad,
      telefono,
      legal_Id,
      toggles: {
        metricas,
        miOrganizacion,
        regulaciones,
        checklist,
        documentos,
        auditorias,
      }
    };

    if (editandoId !== null) {
      const nuevasSucursales = sucursales.map((suc) =>
        suc.id === editandoId ? nuevaSucursal : suc
      );
      setSucursales(nuevasSucursales);
      setEditandoId(null);
    } else {
      setSucursales([...sucursales, nuevaSucursal]);
    }

    limpiarFormulario();
  };

  const limpiarFormulario = () => {
    setNombre('');
    setSelectedOrganizacion('');
    setEstado('');
    setCiudad('');
    setTelefono('');
    setlegal_Id('');
    setMetricas(false);
    setMiOrganizacion(false);
    setRegulaciones(false);
    setChecklist(false);
    setDocumentos(false);
    setAuditorias(false);
  };

  const handleEditar = (id) => {
    const sucursal = sucursales.find((suc) => suc.id === id);
    if (sucursal) {
      setNombre(sucursal.nombre);
      setSelectedOrganizacion(sucursal.organizacion);
      setEstado(sucursal.estado);
      setCiudad(sucursal.ciudad);
      setTelefono(sucursal.telefono);
      setlegal_Id(sucursal.legal_Id);
      setMetricas(sucursal.toggles.metricas);
      setMiOrganizacion(sucursal.toggles.miOrganizacion);
      setRegulaciones(sucursal.toggles.regulaciones);
      setChecklist(sucursal.toggles.checklist);
      setDocumentos(sucursal.toggles.documentos);
      setAuditorias(sucursal.toggles.auditorias);
      setEditandoId(id);
      setFormularioAbierto(true);
    }
  };

  const handleEliminar = (id) => {
    setSucursales(sucursales.filter((suc) => suc.id !== id));
  };

  const handleCopiar = (id) => {
    const sucursal = sucursales.find((suc) => suc.id === id);
    if (sucursal) {
      const copia = { ...sucursal, id: sucursales.length + 1 };
      setSucursales([...sucursales, copia]);
    }
  };

  return (
    <Container style={{ marginTop: '1em', marginLeft: 0 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Sucursales
      </Typography>
      <Accordion expanded={formularioAbierto} onChange={() => setFormularioAbierto(!formularioAbierto)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Formulario de Organización</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  value={nombre}
                  onChange={(e) => handleInputChange(e, setNombre)}
                  margin="normal"
                  required
                  name="Nombre"
                />
                {/* <FormControl fullWidth margin="normal" required error={!!errorOrganizacion}>
                  <InputLabel id="select-organizacion-label">Organización</InputLabel>
                  <Select
                      labelId="select-organizacion-label"
                      id="select-organizacion"
                      value={selectedOrganizacion}
                      onChange={handleInputChange(setSelectedOrganizacion())}
                      variant="outlined"
                  >
                    <MenuItem value="">Seleccione una organización</MenuItem>
                    {organizaciones.map((org) => (
                        <MenuItem key={org.id} value={org.nombre}>
                          {org.nombre}
                        </MenuItem>
                    ))}
                  </Select>
                  {errorOrganizacion && (
                      <Typography variant="caption" color="error">
                        {errorOrganizacion}
                      </Typography>
                  )}
                </FormControl> */}
                <FormControl fullWidth margin="normal" required error={!!errorOrganizacion}> 
                  <InputLabel id="select-organizacion-label">Organización</InputLabel> 
                  <Select labelId="select-organizacion-label" id="select-organizacion" value={selectedOrganizacion} onChange={(e) => handleInputChange(e, setSelectedOrganizacion)} variant="outlined" > 
                    <MenuItem value="">Seleccione una organización</MenuItem> {organizaciones.map((org) => (<MenuItem key={org.id} value={org.nombre}> {org.nombre} </MenuItem>))} 
                  </Select> {errorOrganizacion && (<Typography variant="caption" color="error"> {errorOrganizacion} </Typography>)} 
                </FormControl>
                <TextField
                  label="Estado"
                  variant="outlined"
                  fullWidth
                  value={estado}
                  onChange={(e) => handleInputChange(e, setEstado)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Ciudad"
                  variant="outlined"
                  fullWidth
                  value={ciudad}
                  onChange={(e) => handleInputChange(e, setCiudad)}
                  margin="normal"
                />
                <TextField
                  label="Teléfono"
                  variant="outlined"
                  fullWidth
                  value={telefono}
                  onChange={(e) => handleInputChange(e, setTelefono)}
                  margin="normal"
                />
                <TextField
                  label="ID Legal"
                  variant="outlined"
                  fullWidth
                  value={legal_Id}
                  onChange={(e) => handleInputChange(e, setlegal_Id)}
                  margin="normal"
                />
              </Grid>
            </Grid>

            {/* Sección de Toggles */}
            <Grid container spacing={2} style={{ marginTop: '1em' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={metricas} onChange={() => setMetricas(!metricas)} />}
                  label="Métricas"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={miOrganizacion} onChange={() => setMiOrganizacion(!miOrganizacion)} />}
                  label="Mi Organización"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={regulaciones} onChange={() => setRegulaciones(!regulaciones)} />}
                  label="Regulaciones"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={checklist} onChange={() => setChecklist(!checklist)} />}
                  label="Checklist"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={documentos} onChange={() => setDocumentos(!documentos)} />}
                  label="Documentos"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch checked={auditorias} onChange={() => setAuditorias(!auditorias)} />}
                  label="Auditorías"
                />
              </Grid>
            </Grid>

            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1em' }}>
              {editandoId !== null ? 'Guardar Edición' : 'Guardar'}
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Tabla de Sucursales */}
      {sucursales.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: '2em' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Organización</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Ciudad</TableCell>
                <TableCell>Teléfono</TableCell>
                <TableCell>ID Legal</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sucursales.map((sucursal) => (
                <>
                  <TableRow key={sucursal.id}>
                    <TableCell>{sucursal.nombre}</TableCell>
                    <TableCell>{sucursal.organizacion}</TableCell>
                    <TableCell>{sucursal.estado}</TableCell>
                    <TableCell>{sucursal.ciudad}</TableCell>
                    <TableCell>{sucursal.telefono}</TableCell>
                    <TableCell>{sucursal.legal_Id}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditar(sucursal.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleEliminar(sucursal.id)}>
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleCopiar(sucursal.id)}>
                        <ContentCopy />
                      </IconButton>
                      {editandoId === sucursal.id && (
                        <IconButton onClick={handleSubmit}>
                          <Save />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Grid container spacing={1}>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Métricas: {sucursal.toggles.metricas ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Mi Organización: {sucursal.toggles.miOrganizacion ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Regulaciones: {sucursal.toggles.regulaciones ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Checklist: {sucursal.toggles.checklist ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Documentos: {sucursal.toggles.documentos ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            Auditorías: {sucursal.toggles.auditorias ? 'Sí' : 'No'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default Sucursales;
