import React, { useState } from 'react';
import {
  Container,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import { FileCopy, Edit, Delete } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/CancelOutlined';


const Checklist = () => {
  const [sucursal, setSucursal] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [requerimiento, setRequerimiento] = useState(null);  // Cambiado a objeto
  const [estado, setEstado] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [checklists, setChecklists] = useState([
    { id: 1, requerimiento: 'Requerimiento 1', sucursal: 'Sucursal 1', estado: 'Cumple', periodo: 2024 },
    { id: 2, requerimiento: 'Requerimiento 2', sucursal: 'Sucursal 2', estado: 'No Cumple', periodo: 2024 },
    { id: 3, requerimiento: 'Requerimiento 3', sucursal: 'Sucursal 1', estado: 'Observacion', periodo: 2023 },
    { id: 4, requerimiento: 'Requerimiento 4', sucursal: 'Sucursal 2', estado: 'Cumple', periodo: 2024 },
  ]);
  const [editandoId, setEditandoId] = useState(null); // Para saber si estamos editando un registro
  const [errorSucursal, setErrorSucursal] = useState('');
  const [errorPeriodo, setErrorPeriodo] = useState('');
  const [errorRequerimiento, setErrorRequerimiento] = useState('');
  const [errorEstado, setErrorEstado] = useState('');

  const sucursales = ['Sucursal 1', 'Sucursal 2']; // Lista de sucursales
  const periodos = [2023, 2024]; // Lista de periodos
  const requerimientos = [
    { nombre: 'Requerimiento A', elementos: ['Elemento A1', 'Elemento A2'] },
    { nombre: 'Requerimiento B', elementos: ['Elemento B1', 'Elemento B2'] }
  ]; // Lista de requerimientos (esto se puede personalizar según tus necesidades)

  // Handle Form Submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validación simple (puedes expandirla)
    if (!sucursal || !requerimiento || !estado || !periodo) {
      if (!sucursal) setErrorSucursal('Sucursal es obligatorio');
      if (!requerimiento) setErrorRequerimiento('Requerimiento es obligatorio');
      if (!estado) setErrorEstado('Estado es obligatorio');
      if (!periodo) setErrorPeriodo('Periodo es obligatorio');
      return;
    }

    const newChecklist = {
      id: editandoId !== null ? editandoId : Date.now(), // Si estamos editando, usamos el ID existente
      requerimiento: requerimiento.nombre, // Guardamos el nombre del requerimiento
      sucursal,
      estado,
      periodo: parseInt(periodo, 10),
    };

    if (editandoId !== null) {
      // Actualizar checklist existente
      setChecklists((prev) =>
        prev.map((item) => (item.id === editandoId ? newChecklist : item))
      );
    } else {
      // Agregar nuevo checklist
      setChecklists((prev) => [...prev, newChecklist]);
    }

    // Limpiar el formulario
    setSucursal('');
    setRequerimiento(null);  // Resetear a null
    setEstado('');
    setPeriodo('');
    setEditandoId(null);
    setErrorSucursal('');
    setErrorRequerimiento('');
    setErrorEstado('');
    setErrorPeriodo('');
  };

  // Filtro de la tabla
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSucursalChange = (event, newValue) => {
    setSucursal(newValue);
  };

  const handlePeriodoChange = (event) => {
    setPeriodo(event.target.value);
  };

  const limpiarFiltros = () => {
    setSucursal('');
    setPeriodo('');
    setSearchQuery('');
  };

  return (
    <Container>
      {/* Formulario para agregar o editar un checklist */}
      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">Formulario de Checklist</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Primer campo Autocomplete para Sucursal */}
                <Autocomplete
                  options={sucursales}
                  value={sucursal}
                  onChange={handleSucursalChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sucursal"
                      error={!!errorSucursal}
                      helperText={errorSucursal}
                      margin="dense"
                    />
                  )}
                  fullWidth
                />

                {/* Segundo campo Autocomplete para Requerimiento */}
                <Autocomplete
                  options={requerimientos}
                  getOptionLabel={(option) => option.nombre} // Mostrar nombre del requerimiento
                  value={requerimiento} // Usar objeto completo
                  onChange={(event, newValue) => setRequerimiento(newValue)} // Cambiar al objeto completo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Requerimiento"
                      error={!!errorRequerimiento}
                      helperText={errorRequerimiento}
                      margin="dense"
                    />
                  )}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {/* Campo de Estado */}
                <FormControl fullWidth margin="dense">
                  <InputLabel>Estado</InputLabel>
                  <Select value={estado} onChange={(e) => setEstado(e.target.value)}>
                    <MenuItem value="Cumple">
                      <CheckCircleIcon style={{ color: 'green' }} /> Cumple
                    </MenuItem>
                    <MenuItem value="Observacion">
                      <ErrorIcon style={{ color: 'orange' }} /> Observación
                    </MenuItem>
                    <MenuItem value="No Cumple">
                      <CancelIcon style={{ color: 'red' }} /> No Cumple
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* Campo Año */}
                <TextField
                  label="Año"
                  type="number"
                  fullWidth
                  value={periodo}
                  onChange={(e) => setPeriodo(e.target.value)}
                  error={!!errorPeriodo}
                  helperText={errorPeriodo}
                  margin="dense"
                />
              </Grid>

              {/* Botón de envío */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '1em' }}
                  fullWidth
                >
                  {editandoId !== null ? 'Guardar Cambios' : 'Agregar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Filtros de búsqueda */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={sucursales}
            value={sucursal}
            onChange={handleSucursalChange}
            renderInput={(params) => <TextField {...params} label="Filtrar por Sucursal" />}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Periodo</InputLabel>
            <Select
              value={periodo}
              onChange={handlePeriodoChange}
              label="Filtrar por Periodo"
            >
              {periodos.map((periodoValue) => (
                <MenuItem key={periodoValue} value={periodoValue}>
                  {periodoValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Buscar Requerimiento"
            variant="outlined"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '1em' }}
            onClick={limpiarFiltros}
            fullWidth
          >
            Limpiar filtros
          </Button>
        </Grid>
      </Grid>

      {/* Tabla de checklists */}
      <TableContainer component={Paper} style={{ marginTop: '1em' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Requerimiento</TableCell>
              <TableCell>Sucursal</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checklists
              .filter((checklist) => {
                return (
                  (!searchQuery ||
                    checklist.requerimiento.toLowerCase().includes(searchQuery.toLowerCase())) &&
                  (!sucursal || checklist.sucursal === sucursal) &&
                  (!periodo || checklist.periodo === periodo)
                );
              })
              .map((checklist) => (
                <TableRow key={checklist.id}>
                  <TableCell>{checklist.requerimiento}</TableCell>
                  <TableCell>{checklist.sucursal}</TableCell>
                  <TableCell>{checklist.estado}</TableCell>
                  <TableCell>{checklist.periodo}</TableCell>
                  <TableCell>
                    <IconButton>
                      <Edit />
                    </IconButton>
                    <IconButton>
                      <Delete />
                    </IconButton>
                    <IconButton>
                      <FileCopy />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Checklist;
