import { useNavigate } from 'react-router-dom';
import { AppBar, CssBaseline, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Container, Typography, Menu, MenuItem } from '@mui/material';
import { AccountCircle, Category, Checklist as ChecklistIcon, Contacts, Mail, Folder, Business, Public, Lock, Settings, Assessment, Menu as MenuIcon } from '@mui/icons-material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import AuditResult from './AuditResult';
import Categorias from './Categorias';
import Checklist from './Checklist';
import Contactos from './Contactos';
import Country from './Country';
import DistribucionCorreo from './DistribucionCorreo';
import Documentos from './Documentos';
import Organizacion from './Organizacion';
import Permisos from './Permisos';
import PermisosSucursal from './PermisosSucursal';
import RegulacionesSucursal from './RegulacionesSucursal';
import Regulation from './Regulation';
import Requerimientos from './Requerimientos';
import SubCategorias from './SubCategorias';
import Sucursales from './Sucursales';
import TipoDocumento from './TipoDocumento';
import VistaChecklist from './Vista de Checklist';
import Vistadocumentos from './Vista Documentos';

const drawerWidth = 240;

const DashboardPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [modulosPermitidos, setModulosPermitidos] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.nombre);
      setIsAdmin(decodedToken.admin);
      setModulosPermitidos(decodedToken.MODULOS || []);
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleClose();
    navigate('/');
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <div style={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" style={{ zIndex: 1201 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={toggleDrawer} style={{ marginRight: '16px' }}>
              {isDrawerOpen ? <ChevronLeft /> : <MenuIcon />}
            </IconButton>
            <IconButton edge="start" color="inherit" component={Link} to="/dashboard/metricas" style={{ marginRight: '16px' }}>
              <img src={'https://legistool-images.nyc3.digitaloceanspaces.com/public/Logo.png'} alt="Logo" style={{ height: '40px' }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div" style={{ flexGrow: 1 }}></Typography>
            <Typography variant="body1" component="div">
              {username}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: isDrawerOpen ? drawerWidth : 64,  // Fijar el ancho del Drawer incluso cuando está cerrado
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isDrawerOpen ? drawerWidth : 64,
              boxSizing: 'border-box',
              overflowX: 'hidden',  // Evitar que el contenido se desborde horizontalmente
            },
          }}
        >
          <Toolbar />
          <List>
            {isAdmin ? (
              <>
                <ListItem component={Link} to="/dashboard/metricas" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Assessment /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Metricas" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/categorias" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Category /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Categorías" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/checklist" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><ChecklistIcon /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Checklist" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/contactos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Contacts /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Contactos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/distribucion-correo" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Mail /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Distribución de Correos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/documentos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Folder /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Documentos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/organizacion" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Business /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Organización" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/paises" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Public /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Paises" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/permisos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Lock /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Permisos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/permisossucursales" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Settings /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Permisos por sucursal" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/regulation" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Assessment /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Regulaciones" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/regulacionessucursales" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Settings /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Regulaciones por sucursal" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/requerimientos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Category /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Requerimientos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/subcategorias" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Category /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Subcategorías" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/sucursales" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Business /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Sucursales" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/tipodocumento" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Folder /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Tipo Documento" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/vistadocumentos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Folder /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Documentos" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/vistapermisos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Assessment /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Regulaciones" sx={{ color: 'black' }} />}
                </ListItem>
                <ListItem component={Link} to="/dashboard/vistachecklist" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><ChecklistIcon /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Checklist" sx={{ color: 'black' }} />}
                </ListItem>
              </>
            ) : (
              <>
               {modulosPermitidos.includes("vista-metricas") && (
                <ListItem component={Link} to="/dashboard/vistadocumentos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Folder /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Documentos" sx={{ color: 'black' }} />}
                </ListItem>
                )}
                 {modulosPermitidos.includes("vista-metricas") && (
                <ListItem component={Link} to="/dashboard/vistapermisos" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><Assessment /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Regulaciones" sx={{ color: 'black' }} />}
                </ListItem>
                )}
                {modulosPermitidos.includes("vista-metricas") && (
                <ListItem component={Link} to="/dashboard/vistachecklist" sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                  <ListItemIcon><ChecklistIcon /></ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Vista Checklist" sx={{ color: 'black' }} />}
                </ListItem>
                )}
              </>
            )}
          </List>
        </Drawer>
        <main style={{ flexGrow: 1, padding: '16px' }}>
          <Toolbar />
          <Routes>
            <Route path="/metricas" element={<AuditResult />} />
            <Route path="/categorias" element={<Categorias />} />
            <Route path="/checklist" element={<Checklist />} />
            <Route path="/contactos" element={<Contactos />} />
            <Route path="/distribucion-correo" element={<DistribucionCorreo />} />
            <Route path="/documentos" element={<Documentos />} />
            <Route path="/organizacion" element={<Organizacion />} />
            <Route path="/paises" element={<Country />} />
            <Route path="/permisos" element={<Permisos />} />
            <Route path="/permisossucursales" element={<PermisosSucursal />} />
            <Route path="/regulation" element={<Regulation />} />
            <Route path="/regulacionessucursales" element={<RegulacionesSucursal />} />
            <Route path="/requerimientos" element={<Requerimientos />} />
            <Route path="/subcategorias" element={<SubCategorias />} />
            <Route path="/sucursales" element={<Sucursales />} />
            <Route path="/tipodocumento" element={<TipoDocumento />} />
            <Route path="/vistadocumentos" element={<Vistadocumentos />} />
            <Route path="/vistapermisos" element={<vistapermisos />} />
            <Route path="/vistachecklist" element={<VistaChecklist />} />
            <Route path="/" element={<div>Bienvenido a Legistool.</div>} />
          </Routes>
        </main>
      </div>
    </Container>
  );
};

export default DashboardPage;
