import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Edit, FileCopy, ExpandMore } from '@mui/icons-material';
import axios from 'axios';

const Organizacion = () => {
  const [nombre, setNombre] = useState('');
  const [cedulaJuridica, setCedulaJuridica] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [organizaciones, setOrganizaciones] = useState([]);
  const [paises, setPaises] = useState([]);
  const [selectedPais, setSelectedPais] = useState('');
  const [activo, setActivo] = useState(false);
  const [errorNombre, setErrorNombre] = useState('');
  const [errorPais, setErrorPais] = useState('');
  const [errorCedula, setErrorCedula] = useState('');
  const [editandoId, setEditandoId] = useState(null);
  const [formularioAbierto, setFormularioAbierto] = useState(false);

  useEffect(() => {
    const fetchPaises = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/pais');
        setPaises(response.data);
      } catch (error) {
        console.error('Error obteniendo países', error);
      }
    };

    const fetchOrganizaciones = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/organizacion');
        setOrganizaciones(response.data.organizaciones || []);
      } catch (error) {
        console.error('Error obteniendo organizaciones:', error);
      }
    };

    fetchPaises();
    fetchOrganizaciones();
  }, []);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;

    if (nombre.trim() === '') {
      setErrorNombre('El nombre es requerido');
      hasError = true;
    } else {
      setErrorNombre('');
    }

    if (organizaciones.some((org) => org.nombre.trim().toLowerCase() === nombre.trim().toLowerCase()) && editandoId === null) {
      setErrorNombre('El nombre ya existe');
      hasError = true;
    } else {
      setErrorNombre('');
    }

    if (selectedPais.trim() === '') {
      setErrorPais('El país es requerido');
      hasError = true;
    } else {
      setErrorPais('');
    }

    if (cedulaJuridica.trim() === '') {
      setErrorCedula('La cédula jurídica es requerida');
      hasError = true;
    } else {
      setErrorCedula('');
    }

    if (hasError) return;

    const organizacionData = {
      nombre,
      pais: paises.find((pais) => pais.codigo === selectedPais)?.codigo,
      cedulaJuridica,
      razonSocial,
      descripcion,
      activo,
    };

    try {
      if (editandoId !== null) {
        await axios.put(`http://localhost:8080/api/organizacion/${editandoId}`, organizacionData);
        organizacionData.pais = paises.find((pais) => pais.codigo === organizacionData.pais)
        const nuevasOrganizaciones = organizaciones.map((org) =>
          org.id === editandoId ? { ...org, ...organizacionData } : org
        );
        setOrganizaciones(nuevasOrganizaciones);
        setEditandoId(null);
      } else {
        const response = await axios.post('http://localhost:8080/api/organizacion', organizacionData);
        setOrganizaciones([...organizaciones, { id: response.data.id, ...organizacionData }]);
      }
      resetForm();
    } catch (error) {
      console.error('Error guardando organización', error);
    }
  };

  const resetForm = () => {
    setNombre('');
    setCedulaJuridica('');
    setRazonSocial('');
    setDescripcion('');
    setSelectedPais('');
    setActivo(false);
    setEditandoId(null);
    setFormularioAbierto(false);
  };

  const handleCopy = (nombre) => {
    setNombre(nombre);
    setFormularioAbierto(true);
  };

  const handleEdit = (id) => {
    const organizacionEdit = organizaciones.find((org) => org.id === id);
    if (organizacionEdit) {
      setNombre(organizacionEdit.nombre);
      setCedulaJuridica(organizacionEdit.cedulaJuridica);
      setRazonSocial(organizacionEdit.razonSocial);
      setDescripcion(organizacionEdit.descripcion);
      console.log("before",selectedPais);
      setSelectedPais(organizacionEdit.pais.codigo); // Usa el código del país
      console.log("after",selectedPais);
      setActivo(organizacionEdit.activo);
      setEditandoId(id);
      setFormularioAbierto(true);
    }
  };

  const handleToggleEstado = async (id) => {
    const organizacion = organizaciones.find((org) => org.id === id);

    if (organizacion) {
      try {
        // Get the current active status (activo) and determine the new state
        const estadoActual = organizacion.activo;
        const nuevoEstado = estadoActual === "ACTIVO" ? "INACTIVO" : "ACTIVO";

        // Update the status on the backend
        await axios.put(`http://localhost:8080/api/organizacion/${id}/${nuevoEstado}`, { estado: nuevoEstado });

        // Update the state locally (in React state)
        setOrganizaciones((prevOrganizaciones) => {
          const updatedOrganizaciones = prevOrganizaciones.map((org) =>
              org.id === id ? { ...org, activo: nuevoEstado } : org
          );
          return updatedOrganizaciones;
        });
      } catch (error) {
        console.error('Error actualizando el estado activo', error);
      }
    }
  };

  return (
    <Container style={{ marginTop: '1em', marginLeft: 0 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Organización
      </Typography>
      <Accordion expanded={formularioAbierto} onChange={() => setFormularioAbierto(!formularioAbierto)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Formulario de Organización</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  value={nombre}
                  onChange={handleInputChange(setNombre)}
                  margin="normal"
                  error={!!errorNombre}
                  helperText={errorNombre}
                  required
                />
                <TextField
                  label="Cédula Jurídica"
                  variant="outlined"
                  fullWidth
                  value={cedulaJuridica}
                  onChange={handleInputChange(setCedulaJuridica)}
                  margin="normal"
                  error={!!errorCedula}
                  helperText={errorCedula}
                  required
                />
                <TextField
                  label="Razón Social"
                  variant="outlined"
                  fullWidth
                  value={razonSocial}
                  onChange={handleInputChange(setRazonSocial)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Descripción"
                  variant="outlined"
                  fullWidth
                  value={descripcion}
                  onChange={handleInputChange(setDescripcion)}
                  margin="normal"
                  multiline
                  rows={2}
                />
                <FormControl fullWidth margin="normal" required error={!!errorPais}>
                  <InputLabel id="select-pais-label">País</InputLabel>
                  <Select
                    labelId="select-pais-label"
                    id="select-pais"
                    value={selectedPais}
                    onChange={handleInputChange(setSelectedPais)}
                    variant="outlined"
                  >
                    <MenuItem value="">Seleccione un país</MenuItem>
                    {paises.map((pais) => (
                      <MenuItem key={pais.codigo} value={pais.codigo}>
                        {pais.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorPais && (
                    <Typography variant="caption" color="error">
                      {errorPais}
                    </Typography>
                  )}
                </FormControl>
                <Button type="submit" variant="contained" color="primary">
                  {editandoId !== null ? 'Guardar Edición' : 'Guardar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Cédula Jurídica</TableCell>
                  <TableCell>Razón Social</TableCell>
                  <TableCell>País</TableCell>
                  <TableCell>Activo</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizaciones.map((org) => (
                  <TableRow key={org.id}>
                    <TableCell>{org.nombre}</TableCell>
                    <TableCell>{org.cedulaJuridica}</TableCell>
                    <TableCell>{org.razonSocial}</TableCell>
                    <TableCell>{org.pais.nombre}</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={org.estado === 'ACTIVO' ? true : false}
                            onChange={() => handleToggleEstado(org.id)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEdit(org.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleCopy(org.nombre)}>
                        <FileCopy />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Organizacion;
